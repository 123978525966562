enum pagerDutySeverity {
  info = 'info',
  warning = 'warning',
  error = 'error',
  critical = 'critical',
}

const url = 'https://events.pagerduty.com/v2/enqueue';

const headers = {
  'Content-Type': 'application/json',
};
interface pagerDutyOptions {
  component?: String;
  custom_details?: {}; // arbitrary object for helper data
}

// -------- Mainnet Attestor Alerts --------
export function fireMainnetCoordinatorUnhealthyError() {
  firePagerDutyAlert(
    `Mainnet coordinator is unhealthy`,
    pagerDutySeverity.error,
    'mainnet-coordinator-unhealthy-error',
    {} as pagerDutyOptions
  );
}

export function clearMainnetCoordinatorUnhealthyError() {
  clearPagerDutyAlert(
    'Mainnet coordinator is now healthy',
    pagerDutySeverity.info,
    'mainnet-coordinator-unhealthy-error',
    {} as pagerDutyOptions
  );
}

export function fireTestnetCoordinatorUnhealthyWarning() {
  firePagerDutyAlert(
    `Testnet coordinator is unhealthy`,
    pagerDutySeverity.warning,
    'testnet-coordinator-unhealthy-warning',
    {} as pagerDutyOptions
  );
}

export function clearTestnetCoordinatorUnhealthyWarning() {
  clearPagerDutyAlert(
    'Testnet coordinator is now healthy',
    pagerDutySeverity.info,
    'testnet-coordinator-unhealthy-warning',
    {} as pagerDutyOptions
  );
}

export function fireMainnetAttestorUnhealthyError(numberUnhealthy: number) {
  firePagerDutyAlert(
    `${numberUnhealthy} Mainnet attestors are unhealthy`,
    pagerDutySeverity.error,
    'mainnet-attestors-unhealthy-error',
    {} as pagerDutyOptions
  );
}

export function fireMainnetAttestorUnhealthyWarning(numberUnhealthy: number) {
  firePagerDutyAlert(
    `${numberUnhealthy} Mainnet attestors are unhealthy`,
    pagerDutySeverity.warning,
    'mainnet-attestors-unhealthy-warning',
    {} as pagerDutyOptions
  );
}

export function clearMainnetAttestorUnhealthyError() {
  clearPagerDutyAlert(
    'Mainnet attestors are now healthy',
    pagerDutySeverity.info,
    'mainnet-attestors-unhealthy-error',
    {} as pagerDutyOptions
  );
}

export function clearMainnetAttestorUnhealthyWarning() {
  clearPagerDutyAlert(
    'Mainnet attestors are now healthy',
    pagerDutySeverity.info,
    'mainnet-attestors-unhealthy-warning',
    {} as pagerDutyOptions
  );
}

// -------- Testnet Attestor Alerts --------

export function fireTestnetAttestorUnhealthyWarning(numberUnhealthy: number) {
  firePagerDutyAlert(
    `${numberUnhealthy} Testnet attestors are unhealthy`,
    pagerDutySeverity.warning,
    'testnet-attestors-unhealthy-warning',
    {} as pagerDutyOptions
  );
}

export function clearTestnetAttestorUnhealthyWarning() {
  clearPagerDutyAlert(
    'Testnet attestors are now healthy',
    pagerDutySeverity.info,
    'testnet-attestors-unhealthy-warning',
    {} as pagerDutyOptions
  );
}

// -------- Devnet Attestor Alerts --------

export function firePagerDutyTestAlert() {
  firePagerDutyAlert(
    'Test: Error on status site',
    pagerDutySeverity.error,
    'test-event-type-1',
    {} as pagerDutyOptions
  );
}

export function clearPagerDutyTestAlert() {
  clearPagerDutyAlert(
    'Test: Error on status site',
    pagerDutySeverity.info,
    'test-event-type-1',
    {} as pagerDutyOptions
  );
}

function clearPagerDutyAlert(
  summary: String,
  severity: pagerDutySeverity,
  dedup_key: String,
  options: pagerDutyOptions
) {
  const body = JSON.stringify({
    payload: {
      summary: summary,
      timestamp: '',
      source: 'status.dlc.link',
      severity: severity,
      component: options.component,
      group: '',
      class: '',
      custom_details: options.custom_details,
    },
    dedup_key: dedup_key,
    routing_key: 'R03E5A932U3J2XY99ZQM4NEM95J5IFSY',
    links: [
      {
        href: 'https://status.dlc.link/',
        text: 'Status Page',
      },
    ],
    event_action: 'resolve',
    client: 'DLC.Link Monitoring Service',
    client_url: 'https://status.dlc.link',
  });

  fetch(url, {
    method: 'POST',
    headers: headers,
    body: body,
  })
    .then((response) => response.json())
    .then((data) => console.log('Success:', data))
    .catch((error) => console.error('Error:', error));
}

function firePagerDutyAlert(
  summary: String,
  severity: pagerDutySeverity,
  dedup_key: String,
  options: pagerDutyOptions
) {
  // https://developer.pagerduty.com/send-event-form/
  const body = JSON.stringify({
    payload: {
      summary: summary,
      timestamp: '',
      source: 'status.dlc.link',
      severity: severity,
      component: options.component,
      group: '',
      class: '',
      custom_details: options.custom_details,
    },
    dedup_key: dedup_key,
    routing_key: 'R03E5A932U3J2XY99ZQM4NEM95J5IFSY',
    // images: [
    //   {
    //     src: 'https://www.pagerduty.com/wp-content/uploads/2016/05/pagerduty-logo-green.png',
    //     href: 'https://test.com/',
    //     alt: 'Example text',
    //   },
    // ],
    links: [
      {
        href: 'https://status.dlc.link/',
        text: 'Status Page',
      },
    ],
    event_action: 'trigger',
    client: 'DLC.Link Monitoring Service',
    client_url: 'https://status.dlc.link',
  });

  fetch(url, {
    method: 'POST',
    headers: headers,
    body: body,
  })
    .then((response) => response.json())
    .then((data) => console.log('Success:', data))
    .catch((error) => console.error('Error:', error));
}
